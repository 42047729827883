'use client';
import { GoogleAuthProvider, signInWithCredential } from 'firebase/auth';
import { useCallback, useEffect, useState } from 'react';
import { useTellerSession } from '~/common/hooks/use-auth/use-teller-session';
import { useClientOnlyMediaQuery } from '~/common/hooks/use-client-ony-media-query';
import {
  sendToFirebaseAnalytics,
  setFirebaseAuthUserProperties,
} from '~/common/hooks/use-firebase-analytics';
import { firebaseAuth } from '~/lib/firebase';
import {
  Providers,
  providerString,
  signInNextAuthFromFirebaseUser,
} from '~/usecases/auth-use-case';
import { Media } from '~/utils/styles/enums';
import { useIsShownAppIntroduction } from '@app/_containers/app-introduction-actionsheet-container/use-local-hooks';
import { useSystemNotification } from '@app/_hooks/use-system-notification';
import { useSetShowingGoogleOneTapLoginPrompt } from '@app/_state/showing-google-one-tap-login-prompt-state';

export const useGoogleOneTapLogin = () => {
  const { session } = useTellerSession();
  const [isRequestedPrompt, setIsRequestedPrompt] = useState(false);

  // Do not show OneTapLogin when the AppIntroductionActionsheet is displayed
  const { isShownAppIntroduction } = useIsShownAppIntroduction();
  const screenLarge = useClientOnlyMediaQuery(Media.LARGE);
  const isMobile =
    typeof screenLarge === 'undefined' ? undefined : !screenLarge;
  const shouldShowAppIntroduction =
    isMobile === true && !isShownAppIntroduction;

  const { setShowingGoogleOneTapLoginPrompt } =
    useSetShowingGoogleOneTapLoginPrompt();
  const isAnonymous = session?.isAnonymous;
  const { notify } = useSystemNotification();

  const handleGoogleOneTapLogin = useCallback(
    async (response: google.accounts.id.CredentialResponse) => {
      try {
        const credential = GoogleAuthProvider.credential(response.credential);
        const userCredential = await signInWithCredential(
          firebaseAuth,
          credential
        );
        const firebaseUser = userCredential.user;

        // セッションに保存
        await signInNextAuthFromFirebaseUser(firebaseUser);

        // update user properties
        setFirebaseAuthUserProperties(userCredential);

        sendToFirebaseAnalytics('registration_finished', {
          provider: providerString(Providers.GOOGLE),
        });

        notify('ログインしました', 'info');
      } catch {
        notify('ログインに失敗しました', 'error');
      }
    },
    [notify]
  );

  useEffect(() => {
    if (
      !isAnonymous ||
      isRequestedPrompt ||
      shouldShowAppIntroduction ||
      typeof google === 'undefined'
    )
      return;

    // request prompt
    google.accounts.id.initialize({
      client_id: process.env['NEXT_PUBLIC_GOOGLE_CLIENT_ID'] || '',
      use_fedcm_for_prompt: true,
      callback: handleGoogleOneTapLogin,
    });

    // display onetap prompt
    google.accounts.id.prompt((notification) => {
      const isDisplayed = notification.isDisplayed();
      setShowingGoogleOneTapLoginPrompt(isDisplayed);
    });
    setIsRequestedPrompt(true);
  }, [
    handleGoogleOneTapLogin,
    isAnonymous,
    isRequestedPrompt,
    isShownAppIntroduction,
    setShowingGoogleOneTapLoginPrompt,
    shouldShowAppIntroduction,
  ]);
};
